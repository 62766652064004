import React from 'react'
import '../App.css'
import './ThankYou.css'

function ThankYou() {
    return (
        <div className='page thank_you'>
            <div className='main'>
                <div className='title'>Thank You!</div>
                <div className='paragraph'>Your form has been submitted</div>
            </div>
        </div>
    )
}

export default ThankYou
